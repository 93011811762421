<template>
  <div class="container" id="shipmentDetail">
    <div class="row">
      <div class="col-md-12">
        <div class="tabBackground">
          <div class="row mt-0">
            <div class="col-6 d-flex justify-content-center align-items-center">
              <div class="w-100 text-center">
                <span style="cursor: pointer; width: 96%" @click="selectTab(0)" id="shipment">
                  Sevkiyat Detayı
                </span>
              </div>
            </div>
            <div class="col-6 d-flex justify-content-center align-items-center">
              <div class="w-100 text-center">
                <span style="cursor: pointer; width: 96%" @click="selectTab(1)" id="track">
                  Canlı Takip
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-6 text-left">
        <span> #{{ advertInfo ? advertInfo.AdvertNo : "" }} </span>
      </div>
      <div class="col-md-6 text-right">
        <span style="float: right">
          <el-button
            class="bg-blue py-3"
            style="margin-top: 12px; float: left"
            @click="
              () => {
                $router.push({ name: 'Sevkiyatlarım' });
              }
            "
            >Geri Dön</el-button
          >
        </span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12 text-left">
        <span style="font-size: 25px">
          {{ advertInfo.ProductType }}
        </span>
      </div>
    </div>
    <div v-if="activeTab === 0" class="row mt-2">
      <div class="col-md-12 text-left">
        <span style="font-size: 16px; font-weight: 600">
          Nakliye Bilgileri
        </span>

        <addressCard
          v-if="advertInfo"
          :data="{
            warehouseCity: advertInfo.WarehouseCity,
            warehouseDistrict: advertInfo.WarehouseDistrict,
            deliveryCompanyCity: advertInfo.DeliveryCompanyCity,
            deliveryCompanyDistrict: advertInfo.DeliveryCompanyDistrict,
            contactName: advertInfo.ContactName,
            contactNumber: advertInfo.ContactNumber,
            preventAccess: user.IsCarrier && advertInfo ? !advertInfo.Supplier : null,
            productType: advertInfo.ProductType
          }"
        />
      </div>
      <div class="col-md-12">
        <div class="col-md-6 mt-3">
          <span style="font-size: 16px; font-weight: 600">
            Şoför Bilgileri
          </span>

          <driverDetail :driverInfo="{ ...advertInfo.Vehicle }" :rate="advertInfo.CarrierRate ? advertInfo.CarrierRate : 'Henüz Oylanmadı'" />
        </div>
        <!-- <div class="col-md-6 my-3">
          <span style="font-size: 16px; font-weight: 600"> Ödeme Yöntemi </span>
          <paymentMethod
            :paymentMethod="{
              paymentType: advertInfo.PaymentType,
            }"
          />
        </div> -->
      </div>
      <div class="col-md-12 mt-2">
        <div class="col-md-6">
          <span style="font-size: 16px; font-weight: 600">
            İletişim Bilgileri
          </span>
          <div class="mb-3">
            <personal-detail
              :personalInfo="{
                ...advertInfo,
                Supplier: advertInfo.Supplier ? advertInfo.Supplier : advertInfo.User,
              }"
              :nakuAmount="systemSettings.CarrierContactInfoNakuAmount"
              :adId="advertInfo ? user.IsCarrier ? advertInfo.ID : advertInfo.ConfirmedBidId: null"
            />
          </div>
        </div>

        <div v-if="!user.IsCarrier && (advertInfo.PaymentType == 0 || advertInfo.PaymentType == 1)" class="col-md-6">
          <span style="font-size: 16px; font-weight: 600">
            Banka Hesabı Bilgileri
          </span>
          <bankAccount :bankInfo="advertInfo.ConfirmedBidderBankAccount" />
        </div>
        <div v-if="advertInfo.VehicleId" class="col-md-6 text-left">
          <span style="font-size: 16px; font-weight: 600">
            Araç Bilgileri
          </span>
          <vehicleInfo :vehicleInfo="advertInfo" />

          <photoDetail :photoDetail="advertInfo.Vehicle" />
        </div>
      </div>
    </div>
    <div v-else class="row mt-2">
      <div class="col-md-12">
        <GmapMap :center="center" :zoom="14" style="width: 100%; height: 400px" ref="mapRef">
          <GmapMarker :position="{ lat: marker.position.lat, lng: marker.position.lng }" />
        </GmapMap>
      </div>
    </div>
  </div>
</template>

<script>
import addressCard from "../../components/addressCard/addressCard";
// import paymentMethod from "../../components/paymentMethod/paymentMethod";
import driverDetail from "../../components/driverDetail/driverDetail";
import photoDetail from "../../components/photoDetail/photoDetail";
import bankAccount from "../../components/bankAccount/bankAccount";
import vehicleInfo from "../../components/vehicleInfo/vehicleInfo";
import personalDetail from "../../components/personalDetail/personalDetail";

import * as $ from "jquery";
import { mapGetters } from "vuex";

export default {
  components: {
    addressCard,
    // paymentMethod,
    driverDetail,
    photoDetail,
    bankAccount,
    vehicleInfo,
    personalDetail,
  },

  data() {
    return {
      activeTab: 0,
      adId: null,
      endDate: "",
      marker: {
        position: {
          lat: 45.508,
          lng: -73.587,
        },
      },
      center: { lat: 45.508, lng: -73.587 },
      mapOptions: {
        disableDefaultUI: true,
      },
      blockSupplierModal: false,
      locationInterval: null,
    };
  },
  async beforeMount() {},

  mounted() {
    $("#shipment").addClass("activeTab");
    // this.geolocate();
  },
  created() {
    this.changeLocation();
    this.locationInterval = setInterval(() => {
      this.changeLocation();
    }, 30000);
  },
  destroyed() {
    clearInterval(this.locationInterval);
  },
  methods: {
    async fetchData() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      let result;
      if (params.status == 3) {
        result = await this.$store.dispatch("getCompletedTransferAdverts", {
          AdvertId: params.adId,
        });
      } else {
        result = await this.$store.dispatch("getTransferAdverts", {
          AdvertId: params.adId,
        });
      }
      return !result.HasError;
    },
    handleBlockUser() {
      this.$store.dispatch("blockUser", { UserId: this.advertInfo.UserId });
      this.handleOpenBlockSupplier();
    },
    handleOpenBlockSupplier() {
      this.blockSupplierModal = !this.blockSupplierModal;
    },
    selectTab(tab) {
      this.activeTab = tab;
    },
    async changeLocation() {
      const result = await this.fetchData();
      if (result && this.advertInfo.User && this.advertInfo.User.Location) {
        this.center = {
          lat: this.advertInfo.User?.Location.Coordinates[1],
          lng: this.advertInfo.User?.Location.Coordinates[0],
        };
        this.marker.position = {
          lat: this.advertInfo.User?.Location.Coordinates[1],
          lng: this.advertInfo.User?.Location.Coordinates[0],
        };
      }
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.marker.position = this.center;
      });
    },
  },

  watch: {
    activeTab(val) {
      if (val === 0) {
        $("#track").removeClass("activeTab");
        $("#shipment").addClass("activeTab");
      } else {
        $("#track").addClass("activeTab");
        $("#shipment").removeClass("activeTab");
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "userState",
      systemSettings: "systemSettings",
    }),
    advertInfo: {
      get() {
        return this.$store.getters["advertInfo"];
      },
    },
    user: {
      get() {
        return this.$store.getters["userState"];
      },
    },
    loading() {
      return this.$store.getters["loading"];
    },
  },
};
</script>

<style scoped>
.block-dialog {
  color: rgba(245, 245, 245, 0.747) !important;
}
.block-button {
  background-color: palevioletred;
}
.cancel-button {
  background-color: transparent;
}
#shipmentDetail span {
  color: white;
  font-size: 14px;
}
.tabBackground {
  border-radius: 16px;
  background-color: #4d668a;
  padding: 10px 0;
}
.col-md-6 {
  margin: auto;
}
.activeTab {
  background-color: #2d405a;
  border-radius: 16px;
  display: inline-block;
  padding: 10px;
}
.buttonDivider {
  border: 1px solid white;
  margin: 0 20px;
  height: 100%;
}
</style>
