var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container",attrs:{"id":"shipmentDetail"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"tabBackground"},[_c('div',{staticClass:"row mt-0"},[_c('div',{staticClass:"col-6 d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"w-100 text-center"},[_c('span',{staticStyle:{"cursor":"pointer","width":"96%"},attrs:{"id":"shipment"},on:{"click":function($event){return _vm.selectTab(0)}}},[_vm._v(" Sevkiyat Detayı ")])])]),_c('div',{staticClass:"col-6 d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"w-100 text-center"},[_c('span',{staticStyle:{"cursor":"pointer","width":"96%"},attrs:{"id":"track"},on:{"click":function($event){return _vm.selectTab(1)}}},[_vm._v(" Canlı Takip ")])])])])])])]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-md-6 text-left"},[_c('span',[_vm._v(" #"+_vm._s(_vm.advertInfo ? _vm.advertInfo.AdvertNo : "")+" ")])]),_c('div',{staticClass:"col-md-6 text-right"},[_c('span',{staticStyle:{"float":"right"}},[_c('el-button',{staticClass:"bg-blue py-3",staticStyle:{"margin-top":"12px","float":"left"},on:{"click":() => {
              _vm.$router.push({ name: 'Sevkiyatlarım' });
            }}},[_vm._v("Geri Dön")])],1)])]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-md-12 text-left"},[_c('span',{staticStyle:{"font-size":"25px"}},[_vm._v(" "+_vm._s(_vm.advertInfo.ProductType)+" ")])])]),(_vm.activeTab === 0)?_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-md-12 text-left"},[_c('span',{staticStyle:{"font-size":"16px","font-weight":"600"}},[_vm._v(" Nakliye Bilgileri ")]),(_vm.advertInfo)?_c('addressCard',{attrs:{"data":{
          warehouseCity: _vm.advertInfo.WarehouseCity,
          warehouseDistrict: _vm.advertInfo.WarehouseDistrict,
          deliveryCompanyCity: _vm.advertInfo.DeliveryCompanyCity,
          deliveryCompanyDistrict: _vm.advertInfo.DeliveryCompanyDistrict,
          contactName: _vm.advertInfo.ContactName,
          contactNumber: _vm.advertInfo.ContactNumber,
          preventAccess: _vm.user.IsCarrier && _vm.advertInfo ? !_vm.advertInfo.Supplier : null,
          productType: _vm.advertInfo.ProductType
        }}}):_vm._e()],1),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"col-md-6 mt-3"},[_c('span',{staticStyle:{"font-size":"16px","font-weight":"600"}},[_vm._v(" Şoför Bilgileri ")]),_c('driverDetail',{attrs:{"driverInfo":{ ..._vm.advertInfo.Vehicle },"rate":_vm.advertInfo.CarrierRate ? _vm.advertInfo.CarrierRate : 'Henüz Oylanmadı'}})],1)]),_c('div',{staticClass:"col-md-12 mt-2"},[_c('div',{staticClass:"col-md-6"},[_c('span',{staticStyle:{"font-size":"16px","font-weight":"600"}},[_vm._v(" İletişim Bilgileri ")]),_c('div',{staticClass:"mb-3"},[_c('personal-detail',{attrs:{"personalInfo":{
              ..._vm.advertInfo,
              Supplier: _vm.advertInfo.Supplier ? _vm.advertInfo.Supplier : _vm.advertInfo.User,
            },"nakuAmount":_vm.systemSettings.CarrierContactInfoNakuAmount,"adId":_vm.advertInfo ? _vm.user.IsCarrier ? _vm.advertInfo.ID : _vm.advertInfo.ConfirmedBidId: null}})],1)]),(!_vm.user.IsCarrier && (_vm.advertInfo.PaymentType == 0 || _vm.advertInfo.PaymentType == 1))?_c('div',{staticClass:"col-md-6"},[_c('span',{staticStyle:{"font-size":"16px","font-weight":"600"}},[_vm._v(" Banka Hesabı Bilgileri ")]),_c('bankAccount',{attrs:{"bankInfo":_vm.advertInfo.ConfirmedBidderBankAccount}})],1):_vm._e(),(_vm.advertInfo.VehicleId)?_c('div',{staticClass:"col-md-6 text-left"},[_c('span',{staticStyle:{"font-size":"16px","font-weight":"600"}},[_vm._v(" Araç Bilgileri ")]),_c('vehicleInfo',{attrs:{"vehicleInfo":_vm.advertInfo}}),_c('photoDetail',{attrs:{"photoDetail":_vm.advertInfo.Vehicle}})],1):_vm._e()])]):_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-md-12"},[_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"400px"},attrs:{"center":_vm.center,"zoom":14}},[_c('GmapMarker',{attrs:{"position":{ lat: _vm.marker.position.lat, lng: _vm.marker.position.lng }}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }